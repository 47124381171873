<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <h4>Página no encontrada.</h4>
        <v-btn text @click="$router.go(-1)">Volver</v-btn>
                <v-btn text @click="$router.push({ name: 'home' })">Ir a inicio</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'E404',
};
</script>

<style lang="scss" scoped>
  .container {
    height: 80vh;
    width: 100vw;
    justify-content: center;
    text-align: center;
    h4 {
      color: #999999;
    }
    .v-btn, h3 {
      color: #195472;
      margin-top: 2em;
    }
  }
  
</style>